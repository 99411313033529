<template>
  <div>
    <v-subheader>
      관리자
    </v-subheader>
    <v-sheet class="px-3 pb-0 d-flex">
      <v-autocomplete
        v-model="user"
        :items="users"
        :search-input.sync="keyword"
        :loading="isLoading"
        prepend-inner-icon="mdi-account-search"
        append-icon="mdi-plus"
        dense
        filled
        rounded
        placeholder="사용자 검색"
        no-data-text="검색된 사용자가 없습니다."
        class="pt-5 no-message"
        @keyup="liveSearch"
        @click:append="addAdmin"
      />
    </v-sheet>
    <v-simple-table>
      <template #default>
        <thead>
          <tr>
            <th />
            <th
              v-for="(col, i) in cols"
              :id="'bought-admin-' + i"
              :key="i"
              class="text-subtitle-1 text-center"
            >
              {{ col }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in admins" :key="i">
            <td class="text-center">
              <v-btn icon @click="retrieveAdmin(item)">
                <v-icon>
                  mdi-trash-can
                </v-icon>
              </v-btn>
            </td>
            <td class="text-center">
              <span class="text-truncate">
                {{ item.id }}
              </span>
            </td>
            <td class="text-center">
              <span class="text-truncate">
                {{ item.username }}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
  export default {
    name: 'Admins',
    props: [
      'cols',
    ],
    data () {
      return {
        admins: [],
        users: [],
        user: {},
        keyword: '',
        isLoading: false,
        selected: [],
      }
    },
    computed: {
    },
    beforeMount () {
      this.getAdmins()
    },
    methods: {
      async getAdmins () {
        const url = '/manage/user/admins'
        await this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.admins = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      async liveSearch () {
        const url = '/manage/user/admins/live'
        const data = {
          keyword: this.keyword,
        }
        await this.$axios.get(url, { params: data })
          .then(res => {
            if (res && res.data) {
              this.users = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      async addAdmin () {
        const url = '/manage/user/admins'
        this.$axios.patch(url, { userId: this.user }).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('처리가 완료되었습니다.')
            this.getAdmins()
            this.keyword = ''
            this.users = []
            this.user = ''
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        })
      },
      async retrieveAdmin (user) {
        if (confirm('관리자 권한을 회수하시겠습니까?')) {
          const url = '/manage/user/admins/retrieve'
          this.$axios.patch(url, user).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('처리가 완료되었습니다.')
              this.getAdmins()
            } else {
              alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
            }
          }).catch(e => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  *::v-deep {
    v-messages {
      display: none;
    }
  }
</style>
