<template>
  <v-row>
    <v-col cols="12" md="12">
      <Admins :cols="cols" />
    </v-col>
  </v-row>
</template>

<script>
  import Admins from './Admins'
  export default {
    name: 'ManageAdminContent',
    components: { Admins },
    data () {
      return {
        cols: ['ID', 'Username'],
      }
    },
  }
</script>

<style scoped>

</style>
