<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <ManageAdminHeader />
      <ManageAdminContent />
    </v-container>
  </v-lazy>
</template>

<script>
  import ManageAdminHeader from '@/views/manage/admin/Header'
  import ManageAdminContent from '@/views/manage/admin/Content'
  export default {
    name: 'Admin',
    components: { ManageAdminContent, ManageAdminHeader },
  }
</script>

<style scoped>

</style>
